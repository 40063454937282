<template>

  <v-stepper v-model="e1" vertical >
    <v-toolbar color="grey" class="elevation-0" dark>
      Formulario de registro del sobre de postulación: 
    </v-toolbar>
    <v-stepper-step :complete="e1 > 1" step="1">Datos Iniciales</v-stepper-step>
    <v-stepper-content step="1" class="py-0 auto-height expand-steep">
      <v-card class="elevation-0">
        <v-card-text class="py-0 px-0">
          <v-form ref="formInicial" v-model="valid" lazy-validation>
            <v-layout row wrap>
              <div class="flex lg3 md4 xs4 font-weight-bold grey--text">
                Número Documento:
              </div>
              <div class="flex lg9 md7 xs7 black--text">
                {{ numeroDocumento }}
              </div>
              <div class="flex lg3 md4 xs4 font-weight-bold grey--text">
                Complemento Documento:
              </div>
              <div class="flex lg9 md7 xs7 black--text">
                {{ complementoDocumento }}
              </div>
              <div class="flex lg3 md4 xs4 font-weight-bold grey--text">
                Fecha nacimiento:
              </div>
              <div class="flex lg9 md7 xs7 black--text">
                {{ fechaNacimiento }}
              </div>
              <div class="flex lg3 md4 xs4 font-weight-bold grey--text">
                Nombres y Apellidos:
              </div>
              <div class="flex lg9 md7 xs7 black--text">
                {{ nombreCompleto }}
              </div>
              <div class="flex lg3 md4 xs4 font-weight-bold grey--text">
                Celular:
              </div>
              <div class="flex lg9 md7 xs7 black--text">
                {{ celular }}
                <v-btn small color="success" class="ml-5" icon @click="modalActualizarCelular">
                    <v-icon medium color="grey darken-4" >mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div class="flex lg3 md4 xs4 font-weight-bold grey--text">
                Correo:
              </div>
              <div class="flex lg9 md7 xs7 black--text">
                {{ correo }}
                <v-btn samll color="success" class="ml-5" icon @click="modalActualizarCorreo">
                    <v-icon medium color="grey darken-4">mdi-pencil</v-icon>
                </v-btn>
              </div>
              <div class="flex lg3 md4 xs4 font-weight-bold grey--text">
                Convocatoria:
              </div>
              <div class="flex lg9 md7 xs7 black--text">
                {{ convocatoria.titulo }}
              </div>
            </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
    </v-stepper-content>
    <br>
    <v-stepper-step :complete="e1 > 2" step="2" class="v-stepper__step--active">
         Seleccione el departamento donde realiza la postulación:
    </v-stepper-step>
    <v-stepper-content step="2" class="pt-0">
                <v-select
                  v-model="postulacion.departamentoSolicitud"
                  class="py-0 pr-2"
                  label="Departamento solicitud"
                  placeholder="Seleccione una opcion"
                  :items="$store.state.session.parametros.departamentos"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  outlined
                  dense
                  clearable
                ></v-select>
    </v-stepper-content>

     <v-stepper-step :complete="e1 > 2" step="2" class="v-stepper__step--active">
         Seleccione el Tribunal de Ética de la Abogacía al que desea postular:
    </v-stepper-step>
    <v-stepper-content step="2" class="pt-0">
      <v-checkbox
                  v-if="!postulacion.nacional"
                  color="success"
                  class="mt-0 py-0 pr-2"
                  v-model="postulacionDepartamento"
                >
                  <template v-slot:label>
                   <div class="label_switch">
                    Tribunal <b>Departamental</b> de Ética de la Abogacia.
                  </div>
                  </template>
                </v-checkbox>
                <v-select
                  v-if="postulacionDepartamento"
                  v-model="postulacion.departamentoPostulacion"
                  class="py-0 pr-2"
                  label="Seleccione el departamento al que postula"
                  placeholder="Seleccione una opción"
                  :items="itemsDepartamentoPostulacion"
                  :rules="[(v) => !!v || 'El campo es requerido']"
                  outlined
                  clearable
                  dense
                ></v-select>

                <v-checkbox
                    v-if="!postulacionDepartamento"
                    color="success"
                    class="mt-0 py-0 pr-2"
                    v-model="postulacion.nacional"
                  >
                  <template v-slot:label>
                   <div class="label_switch">
                    Tribunal <b>Nacional</b> de Ética de la Abogacia.
                   </div>
                  </template>
                </v-checkbox>
    </v-stepper-content>
        




    <v-stepper-step :complete="e1 > 2" step="2" class="v-stepper__step--active">
      Registro de Formación Académica, Experiencia en el Ejercicio de la Abogacía, Docencia y Producción Intelectual
    </v-stepper-step>
    <v-stepper-content step="2" class="pt-0">
      <v-card>
        <v-card-text class="py-0 px-0">
          <v-checkbox
            color="success"
            class="mt-0 py-0 pr-2"
            v-model="selectDocument"
            v-if="!ratificar"
          >
            <template v-slot:label>
              <div class="label_switch">
              Seleccionar o registrar ahora
              <br>
              <small class="success--text">
                En caso de no marcar esta opción, podrá adicionar la documentación señalada hasta el {{formatDateText(convocatoria.fechaCaducidad)}}.
              </small>
              </div>
            </template>
          </v-checkbox>
          <v-checkbox
            color="success"
            class="mt-0 py-0 pr-2"
            v-model="ratificar"
            v-else
            disabled
          >
            <template v-slot:label>
              <div class="label_switch">
              Ratificar información de una postulación anterior
              <br>
              <small class="success--text">
                En caso de no contar con postulaciones anteriores deberá registrar su información de manera obligatoria
              </small>
              </div>
            </template>
          </v-checkbox>
          <template v-if="selectDocument">
            <v-alert outlined type="info" dense class="mb-0 body-2">
              Se recomienda el Área DISCIPLINARIA y PROCESAL
            </v-alert>
            <v-subheader class="font-weight-bold">1.- FORMACIÓN</v-subheader>
            <Formaciones
              :actions="false"
              :select="true"
              @formacionSelecionada="seleccionarFormaciones"
              :datosCreacion="true"
              :areas="areasFormacion"
            />
            <v-subheader class="font-weight-bold">2.- EXPERIENCIA EN EL EJERCICIO DE LA ABOGACÍA</v-subheader>
            <Experiencias
              :actions="false"
              :select="true"
              @datosSeleccionados="seleccionarExperiencias"
              :datosCreacion="true"
              :areas="areasFormacion"
            />
            <v-subheader class="font-weight-bold">3.- DOCENCIA</v-subheader>
            <Docencias
              :actions="false"
              :select="true"
              @datosSeleccionados="seleccionarDocencias"
            />
            <v-subheader class="font-weight-bold">4.- PRODUCCIÓN INTELECTUAL</v-subheader>
            <ProduccionesIntelectuales
              :actions="false"
              :select="true"
              @datosSeleccionados="seleccionarProduccionesIntelectuales"
            />
          </template>
          <template v-if="postulacionesAnteriores.length>0 && ratificar">
            <v-data-table
              :headers="headers"
              :items="postulacionesAnteriores"
              class="elevation-0"
              single-select
              show-select
              v-model="postulacionRatificada"
              item-key="id"
              hide-default-footer
            >
              <template v-slot:item.fechaPostulacion="{item}">
                 {{formatDateText(item.fechaPostulacion)}}
              </template>
              <template v-slot:item.nacional="{ item }">
                {{item.nacional?'NACIONAL':'DEPARTAMENTAL'}}
              </template>
              
            </v-data-table>
          </template>
        </v-card-text>
        
      </v-card>
    </v-stepper-content>

    <v-stepper-step :complete="e1 > 2" step="3" class="v-stepper__step--active">
      Registro de Respaldo Digital de Hoja de Vida
    </v-stepper-step>
    <v-stepper-content step="3" class="pt-1">
      <v-card >
        <v-card-text class="pt-1 pb-0">
            <v-text-field
              outlined
              filled
              label="Carga Documento de Respaldo"
              readonly
              @click="pickFile"
              prepend-icon="mdi-paperclip"
              v-model="fileName"
               placeholder="Presiona aquí para subir tu archivo"
            >
                <template v-if="loadingFile" slot="append-outer">
                  <v-progress-circular
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                </template>
            </v-text-field>
            <input type="file" style="display: none" ref="documento" @change="onFilePicked">
             <template v-if="fileUrl!=''">
                <div id="previewPDF" ></div>
            </template>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            class="mr-2"
            text
            @click="showConfirmacion('Cerrar')"
            >Cancelar</v-btn
          >
          <v-btn
            color="success"
            @click="showConfirmacion('RegistrarPostulacionConvocatoria')"
            class="mr-2"
            :disabled="!valid || disabled"
            >
            <v-icon class="pr-2">mdi-content-save</v-icon>
            guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-stepper-content>

    <Confirmacion
      :pregunta="confirmacion.consultaDialog"
      :consulta="confirmacion.preguntaConsulta"
      :loading="confirmacion.loadingPregunta"
      :cerrar="confirmacion.cerrar"
      @si="confirmacionAceptada"
      @no="cancelarConfirmacion"
    >
      <template v-if="formulario != null" v-slot:extra>
        <v-list two-line subheader>
          <v-list-item@click="descargarArchivo(formulario)">
            <v-list-item-avatar>
              <v-icon color="red">mdi-file-pdf</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Formulario</v-list-item-title>
              <v-list-item-subtitle>Presiona para descargar</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </Confirmacion>

     <actualizacion-contacto 
            v-if="dialog" 
            :dialog="dialog" 
            :url="url" 
            :label="label" 
            :value="valueEdit"
            :rules="rulesEdit"
            @modificado="modificarContacto"
            @cancelar="dialog=false"
      />

  </v-stepper>
</template>
<script>
import Confirmacion from "@/common/util/Confirmacion";
import mixinConvocatoria from "@/mixins/convocatorias";
import mixinDateTime from "@/mixins/datetime"
import PDFObject from "@/../paquetes_externos/pdfobject.min";
import { mapState } from "vuex";

export default {
  components: {
    "actualizacion-contacto":()=>import("@/components/ActualizarContacto"),
    Formaciones: () => import("@/components/Formacion"),
    Experiencias: () => import("@/components/Experiencia"),
    Docencias: () => import("@/components/Docencia"),
    ProduccionesIntelectuales: () =>import("@/components/ProduccionIntelectual"),
    Confirmacion
    
  },
  mixins: [mixinConvocatoria,mixinDateTime],
  mounted() {
    if (!this.isRegistered) {
      this.$router.push({ name: "Home" });
    }
    this.$store.commit("setTituloPricipal", "Nuevo Sobre de Postulación");
    this.getPostulacionesCiudadano()
  },
  data() {
    return {
      e1: 1,
      valid: true,
      postulacion: {
        experiencias: [],
        formaciones: [],
        docencias: [],
        produccionesIntelectuales: [],
        departamentoSolicitud: null,
        departamentoPostulacion:null,
        nacional: false,
      },
      itemsDepartamentoPostulacion:[
        {value:27,text:"LA PAZ"},
        {value:33,text:"SANTA CRUZ"},
        {value:26,text:"COCHABAMBA"},
        {value:25,text:"CHUQUISACA"},
        {value:29,text:"ORURO"},
        {value:32,text:"POTOSI"},
        {value:34, text:"TARIJA"},
        {value:24, text:"BENI"},
        {value:31, text:"PANDO"}
      ],
      confirmacion: {
        consultaDialog: false,
        preguntaConsulta: "",
        tipoConsulta: null,
        loadingPregunta: false,
        cerrar: false,
      },
      tipoDefensa: this.$store.getters.getParameter("tipo_defensor", "RUANNA"),
      loading: false,
      selectDocument:false,
      formulario: null,
      dialog:false,
      ratificar:false,
      postulacionDepartamento:false,
      headers:[
        {
          text: "TÍTULO CONVOCATORIA",
          align: "left",
          value: "titulo",
          sortable: true
        },
        {
          text: "FECHA POSTULACIÓN",
          align: "center",
          sortable: false,
          value: "fechaPostulacion"
        },
        {
          text: "TIPO POSTULACIÓN",
          align: "center",
          sortable: false,
          value: "nacional"
        }
      ],
      convocatoria:{},
      postulacionesAnteriores:[],
      postulacionRatificada:null,
      loadingFile:false,
      fileName: "",
      fileUrl: "",
      file: "",
      disabled:true,
      ruleRequired:[v=>!!v|| `El campo es obligatorio`]
    };
  },
  computed: {
    areasFormacion(){
      return this.$store.state.session.parametros.area_derecho
    },
    cantidadFormaciones() {
      return this.postulacion.formaciones.length;
    },
    cantidadExperiencia() {
      return this.postulacion.experiencias.length;
    },
    cantidadDocencia() {
      return this.postulacion.docencias.length;
    },
    cantidadProduccionesIntelectuales() {
      return this.postulacion.produccionesIntelectuales.length;
    },
    ...mapState({
      numeroDocumento: (state) => state.abogado.persona.numeroDocumento,
      complementoDocumento: (state) =>
        state.abogado.persona.complementoDocumento,
      fechaNacimiento: (state) => state.abogado.persona.fechaNacimiento,
      celular:(state)=>state.abogado.persona.celular,
      correo:(state)=>state.abogado.persona.correo
    }),
    nombreCompleto() {
      return this.$store.getters["abogado/getNombreCompleto"];
    },
  },
  methods: {
    showConfirmacion(tipo) {
      this.confirmacion.tipoConsulta = tipo;
      if (tipo == "Cerrar") {
        this.confirmacion.preguntaConsulta =
          "¿Está seguro de Cancelar su postulación a la <strong>CONVOCATORIA</strong>?.";
      }
      if(tipo == "Reiterar") {
        this.confirmacion.preguntaConsulta =
          "Se encontraron postulaciones anteriores, ¿Deseas <strong>Ratificar la información de una postulación anterior</strong>?. En caso de presionar el botón ACEPTAR deberás selecionar una postulación anterior para migrar la información";
          
      }else if (tipo == "RegistrarPostulacionConvocatoria" && this.$refs.formInicial.validate()) {
        let cantidades=` <ul>
            <li>Cantidad de Formaciones: <strong>${this.cantidadFormaciones}</strong></li>
            <li>Cantidad de Experiencias: <strong>${this.cantidadExperiencia}</strong></li>
            <li>Cantidad de Docencias: <strong>${this.cantidadDocencia}</strong></li>
            <li>Cantidad de Producciones: <strong>${this.cantidadProduccionesIntelectuales}</strong></li>
          </ul>
          `
        if(this.ratificar){
          cantidades=`<div role="alert" class="v-alert mb-0 body-2 v-sheet v-sheet--outlined theme--light v-alert--dense v-alert--outlined orange--text">
            <div class="v-alert__wrapper"><i aria-hidden="true" class="v-icon notranslate v-alert__icon mdi mdi-information theme--light orange--text"></i>
            <div class="v-alert__content">
             Usted eligió Ratificar la información de una postulación en la convocatoria ${this.postulacionRatificada[0].titulo}</div>
            </div>
            </div>`
        }else if(!this.selectDocument) {
          cantidades=`<div role="alert" class="v-alert mb-0 body-2 v-sheet v-sheet--outlined theme--light v-alert--dense v-alert--outlined orange--text">
            <div class="v-alert__wrapper"><i aria-hidden="true" class="v-icon notranslate v-alert__icon mdi mdi-information theme--light orange--text"></i>
            <div class="v-alert__content">
              Usted eligió registrar información de su formación académica, experiencia laboral, docencia y producción intelectual, hasta el dia ${this.formatDateText(this.convocatoria.fechaCaducidad)}. caso contrario su sobre de postulación será inhabilitado </div>
            </div>
            </div>`
        }
        this.confirmacion.preguntaConsulta = `¿Está Seguro de Confirmar el envío de la <strong>POSTULACIÓN  A LA CONVOCATORIA ${this.postulacion.nacional?'NACIONAL':'DEPARTAMENTAL'} </strong> 
        con el siguiente contenido registrado ?. <br/><br/> 
          ${cantidades}
          <br/>
          Las y los abogados postulantes para los cargos como miembros del Tribunal de Ética de la Abogacía, DECLARAN que la información proporcionada, así como 
          la documentación  adjunta,  son verídicas y  fidedignas,  tienen carácter de Declaración Jurada y estarán sujetas a verificación por el MJTI, para los 
          fines consiguientes.
          `;
           
      }else{
        this.$notify({
                    group:'foo',
                    type:'error',
                    title:'ERROR FORMULARIO',
                    text:'Debe llenar los campos obligatorios *'
                })
      }
      this.confirmacion.consultaDialog = true;
     
    },
    confirmacionAceptada() {
      const tipo=this.confirmacion.tipoConsulta
      if (tipo === "Cerrar") {
        this.$router.push({ name: "Home" });
      }
      if(tipo==='Reiterar'){
        this.ratificar=true
        this.confirmacion.consultaDialog = false;
      }else if (tipo=== "RegistrarPostulacionConvocatoria" && this.$refs.formInicial.validate()) {
        this.guardarRegistroPostulacion(this.$route.params.id);
      }
    },
    seleccionarFormaciones(data) {
      this.postulacion.formaciones = data;
    },
    seleccionarExperiencias(data) {
      this.postulacion.experiencias = data;
    },
    seleccionarDocencias(data) {
      this.postulacion.docencias = data;
    },
    seleccionarProduccionesIntelectuales(data) {
      this.postulacion.produccionesIntelectuales = data;
    },
    guardarRegistroPostulacion(id) {
      this.confirmacion.loadingPregunta = true;
      if(this.ratificar&&this.postulacionRatificada){
        this.$http
        .post(
          `${this.$apiUrl}abogado-ciudadano/postulacion-convocatoria/${id}/ratificar`,
          {
            departamentoSolicitud: this.postulacion.departamentoSolicitud,
            departamentoPostulacion:this.postulacion.departamentoPostulacion,
            nacional: this.postulacion.nacional,
            postulacionRatificada:this.postulacionRatificada[0].id,
            rutaDocumentacionRespaldo:this.postulacion.rutaDocumentacionRespaldo
          }
        )
        .then((response) => {
          this.formulario = response.data.data;
          this.descargarArchivo(response.data.data);
          this.confirmacion.loadingPregunta = false;
          this.confirmacion.preguntaConsulta = `Registro de Postulación a la convocatoria realizada correctamente`
          this.confirmacion.cerrar = true;
        })
        .catch((error) => {
          console.log(error);
          this.confirmacion.loadingPregunta = false;
        });
      }else{
        this.$http
        .post(
          `${this.$apiUrl}abogado-ciudadano/postulacion-convocatoria/${id}`,
          this.postulacion
        )
        .then((response) => {
          this.formulario = response.data.data;
          this.descargarArchivo(response.data.data);
          this.confirmacion.loadingPregunta = false;
          this.confirmacion.preguntaConsulta = `Registro de Postulación a la convocatoria realizada correctamente`
          this.confirmacion.cerrar = true;
        })
        .catch((error) => {
          console.log(error);
          this.confirmacion.loadingPregunta = false;
        });
      }
      
    },
    descargarArchivo(data) {
      let fileType = "application/pdf";
      let a = document.createElement("a");
      a.target = "_blank";
      a.download = `${data.titulo}.pdf`;
      a.href = window.URL.createObjectURL(this.b64toBlob(data.pdf, fileType));
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setTimeout(() => {
        URL.revokeObjectURL(a.href);
      }, 1500);
    },
    b64toBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;
      let byteCharacters = atob(b64Data);
      let byteArrays = [];
      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);
        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        let byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      let blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    cancelarConfirmacion(){
      this.confirmacion.consultaDialog=false
      if(this.formulario!==null){
        this.$emit('afterCreate')
      }
    },
    modalActualizarCorreo(){
          this.label = 'Correo'
          this.dialog = true 
          this.valueEdit=this.correo
          this.url = 'abogado-ciudadano/correo'
          this.rulesEdit='required email'
      },
      modalActualizarCelular(){
          this.label = 'Celular'
          this.valueEdit=this.celular
          this.dialog = true 
          this.url = 'abogado-ciudadano/celular'
          this.rulesEdit='required'
      },
      modificarContacto(){
          this.dialog=false
      },
      getPostulacionesCiudadano(){
        this.$http.get(`${this.$apiUrl}abogado-ciudadano/postulacion-convocatoria?convocatoria=${this.$route.params.id}`).then(response=>{
          this.postulacionesAnteriores=response.data.data.postulacionesAnteriores
          if(this.postulacionesAnteriores.length>0){
            this.showConfirmacion('Reiterar')
          }
          this.convocatoria=response.data.data.convocatoria
        }).catch(error=>{
          console.log(error)
        })
      },
      pickFile(){
        this.$refs.documento.click();
      },
      onFilePicked(e){
        try{
        const files = e.target.files
        if (files[0] !== undefined) {
            this.fileName = files[0].name
            if (this.fileName.lastIndexOf(".") <= 0) {
                return
            }
           /* 
              const fr = new FileReader()
              fr.readAsDataURL(files[0])
              fr.addEventListener("load", () => {
                this.fileUrl = fr.result
                this.file = files[0]
                this.postDocumentoRespaldo()
                console.log(this.fileUrl)
                setTimeout(() => {
                this.showDocumento(this.fileUrl);
                }, 10000);
              })
            */
             this.file = files[0]
             this.postDocumentoRespaldo()
             this.fileUrl = URL.createObjectURL(files[0]);
             setTimeout(() => {
                this.showDocumento(this.fileUrl);
                }, 100)
            this.modelo.rutaRespaldoDocumento=this.fileName
            this.disabled=true
        }
        
        }catch(e){
          console.log(e);
        }
      },
      postDocumentoRespaldo(){
         let formData = new FormData();
          formData.append("documentoRespaldo", this.file);
          this.loadingFile=true
          this.$http.post(`${this.$apiUrl}abogado-ciudadano/postulacion-convocatoria/carga-documento`,formData).then(response=>{
              this.postulacion.rutaDocumentacionRespaldo=response.data.data
              this.loadingFile=false
              this.disabled=false
          }).catch(error=>{
              console.log(error)
          this.loadingFile=false

          })
      },
      showDocumento(pdf) {
           try{ const options = {
                height: "350px",
                pdfOpenParams: {
                pagemode: "thumbs",
                navpanes: 0,
                toolbar: 1,
                statusbar: 1,
                view: "FitH"
                }
            };
            PDFObject.embed(pdf, "#previewPDF", options);
           }catch(e){
             console.log(e)
           }
        }
  }
};
</script>
<style>
form .direccion-activa {
  border-left: 5px solid #34bfa3 !important;
}
form .direccion-inactiva {
  border-left: 5px solid #f4516c !important;
}
.rpa-tab-active {
  color: red !important;
}
.v-tabs.fixed-tabs-bar.v-tabs--centered.v-tabs--icons-and-text.theme--dark {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 3.7rem;
  z-index: 2;
}
.theme--light.v-input--is-disabled input {
  color: black !important;
}
.theme--light.v-select .v-chip--disabled,
.theme--light.v-select.v-input--is-disabled .v-select__selections,
.theme--light.v-select .v-select__selection--disabled {
  color: black !important;
}
.theme--light.v-input--is-disabled input {
  color: black !important;
}
.theme--light.v-textarea.v-input--is-disabled {
  color: black !important;
}
.tab-transparent {
  background-color: transparent !important;
  border-color: transparent !important;
}
.v-tabs-slider-wrapper{
  height: 7px !important;
}
.required label::after {
  content: " *";
  color: red;
}
.v-stepper__step--active + .auto-height .v-stepper__wrapper {
    height: auto !important;
}
.v-stepper__wrapper {
    height: 100% !important;
}
</style>
